import { GET_TOP_BROKERS_FAILURE, GET_TOP_BROKERS_REQUEST, GET_TOP_BROKERS_SUCCESS } from "../actions/topBrokers";

interface TopBrokersState {
    loading: boolean;
    topBrokers: any;
    error: string | null;
}

const initialState: TopBrokersState = {
    loading: false,
    topBrokers: null,
    error: null,
};

const topBrokersReducer = (state = initialState, action: any): TopBrokersState => {
    switch (action.type) {
        case GET_TOP_BROKERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_TOP_BROKERS_SUCCESS:
            return {
                topBrokers: action.payload,
                loading: false,
                error: null,
            }
        case GET_TOP_BROKERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}

export default topBrokersReducer;