import { NotificationType } from "../../components/notifications/NotificationItem";
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, SET_NOTIFICATIONS, TOGGLE_NOTIFICATIONS_DRAWER, UPDATE_NOTIFICATION } from "../actions/notifications";

interface NotificationsState {
    isNotificationsDrawerOpen: boolean;
    notifications: NotificationType[];
    newNotifications: NotificationType[];
}

const initialState: NotificationsState = {
    isNotificationsDrawerOpen: false,
    notifications: [],
    newNotifications: [],
};

const notificationsReducer = (
    state = initialState,
    action: any
): NotificationsState => {
    switch (action.type) {
        case TOGGLE_NOTIFICATIONS_DRAWER:
            return {
                ...state,
                isNotificationsDrawerOpen: action.payload,
                newNotifications: action.payload ? [] : state.newNotifications,
            };
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
            };
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [action.payload, ...state.notifications],
                newNotifications: [action.payload, ...state.newNotifications],

            };
        case UPDATE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    notification.id === action.payload ? { ...notification, isRead: true } : notification
                ),
            };

        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.id !== action.payload
                ),
            };
        default:
            return state;
    }
};

export default notificationsReducer;
