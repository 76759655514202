/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";
import { FormikProps } from "formik";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import { AppDispatch } from "../../../../redux/store";
import closeIcon from "../../../../svg/closeIcon.svg";
import { ClientsEditInitialDataProps } from "./config";
import { getWorkAreas } from "../../../../api/workAreasApi";
import { Areas, Client, Employee, EmployeesRoleEnum } from "../../../../types";
import bluePencilIcon from "../../../../svg/bluePencilIcon.svg";
import { getAllEmployeesApi } from "../../../../api/employeesApi";
import { tokenStorage } from "../../../../helpers/storageFunctions";
import { normalizeCapitalization } from "../../../../helpers/normalizeCapitalization";
import {
  formatNumber,
  parseFormattedNumber,
} from "../../../../helpers/formatBudget";
import { areas as areasFromRedux } from "../../../../redux/selectors/workAreas";

const bedrooms = ["ANY", "STUDIO", "1", "2", "3", "4", "5", "6", "7+"];
const availability = ["VACANT", "RENTED", "OFF_PLAN"];
const status = ["WARM", "HOT", "PROSPECTIVE"];

interface EditMenuProps {
  onSubmit: (clientsData: Client) => void;
  formik: FormikProps<ClientsEditInitialDataProps>;
  onClose?: () => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  currentUser: Employee | null;
}

const EditClientMenu: React.FC<EditMenuProps> = ({
  onSubmit,
  formik,
  onClose,
  open,
  setOpen,
  currentUser,
}) => {
  const [brokers, setBrokers] = useState<{ id: number; name: string }[]>([]);
  const areas = useSelector(areasFromRedux);
  const [brokerAreas, setBrokerAreas] = useState<Areas[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const token = tokenStorage().getAccessToken();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (currentUser?.role === EmployeesRoleEnum.BROKER) {
      setBrokerAreas(currentUser.areas);
    }
  }, [currentUser]);

  useEffect(() => {
    if (formik.values.areas) {
      setSelectedAreas(formik.values.areas);
    }
  }, [formik.values.areas]);

  const fetchBrokers = async () => {
    if (currentUser?.role !== EmployeesRoleEnum.BROKER) {
      if (!token) {
        setError("No access token found");
        return;
      }
      const page = 1;
      const limit = 0;
      try {
        setLoading(true);
        const response = await dispatch(
          getAllEmployeesApi(
            token,
            page,
            limit,
            "createdAt",
            "DESC",
            undefined,
            undefined,
            selectedAreas
          )
        );

        if (response?.status === 200) {
          const brokers = response?.data.items.filter(
            (employee: Employee) => employee.role === EmployeesRoleEnum.BROKER
          );

          const brokerOptions = brokers.map((broker: Employee) => ({
            id: broker.id,
            name: `${broker.firstName} ${broker.lastName}`,
          }));

          setBrokers(brokerOptions);
        }
      } catch (error) {
        console.log("error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchAreas = async () => {
    if (
      !token ||
      areas.length > 0 ||
      currentUser?.role === EmployeesRoleEnum.BROKER
    ) {
      return;
    }

    try {
      setLoading(true);
      await dispatch(getWorkAreas(token));
    } catch (error) {
      console.error("Error fetching areas:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectionChange = (event: any, newValue: string[]) => {
    formik.setFieldValue("areas", newValue);
    formik.setFieldValue("broker", null);
    formik.setFieldValue("brokerId", null);
  };

  const filteredAreas = (
    currentUser?.role === EmployeesRoleEnum.BROKER ? brokerAreas : areas
  ).map((area) => area.name);

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        sx={{ background: "#FFF" }}
      >
        <img
          src={bluePencilIcon}
          alt='pencil icon'
        />
      </Button>
      <Drawer
        anchor='right'
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "30%",
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{ display: "flex", flexDirection: "column", padding: "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#000000DE",
                  }}
                >
                  {"Edit personal information"}
                </h2>
                <Button
                  sx={{ minWidth: "0px", padding: "0px" }}
                  onClick={handleClose}
                >
                  <img
                    width={18}
                    height={18}
                    src={closeIcon}
                    alt='filter icon'
                  />
                </Button>
              </Box>
              <Divider
                sx={{
                  width: "100%",
                  height: "2px",
                  paddingBlock: "12px",
                  marginBottom: "20px",
                }}
              />
              <Box sx={{ display: "flex", gap: "16px", marginBottom: "10px" }}>
                {formik.touched.type && !formik.values.type?.length && (
                  <Box sx={{ color: "red", fontSize: "14px" }}>
                    {"Choose at least one type!"}
                  </Box>
                )}
                {["BUYER", "RENTER", "OWNER"].map((type) => (
                  <FormControlLabel
                    key={type}
                    control={
                      <Checkbox
                        color='success'
                        checked={
                          Array.isArray(formik.values.type) &&
                          formik.values.type.includes(type)
                        }
                        onChange={(event) => {
                          const newType = Array.isArray(formik.values.type)
                            ? formik.values.type
                            : [];

                          if (event.target.checked) {
                            formik.setFieldValue("type", [...newType, type]);
                          } else {
                            if (newType.length > 1) {
                              formik.setFieldValue(
                                "type",
                                newType.filter((item) => item !== type)
                              );
                            } else {
                              formik.setFieldTouched("type", true);
                            }
                          }
                        }}
                      />
                    }
                    label={normalizeCapitalization(type)}
                  />
                ))}
              </Box>

              <TextField
                label='Status'
                name='status'
                select
                fullWidth
                value={formik.values.status}
                onChange={formik.handleChange}
                sx={{ marginBottom: "16px" }}
              >
                {status.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              {currentUser?.role !== EmployeesRoleEnum.BROKER ? (
                <Tooltip
                  title={
                    selectedAreas.length === 0
                      ? "Please select at least one area first"
                      : ""
                  }
                  componentsProps={{
                    arrow: {
                      sx: {
                        color: "#FFF",
                      },
                    },
                    tooltip: {
                      sx: {
                        color: "#7E57C2",
                        backgroundColor: "white",
                        fontSize: "16px",
                        padding: "8px",
                        boxShadow:
                          "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                      },
                    },
                  }}
                  placement='top'
                  arrow
                  disableHoverListener={selectedAreas.length !== 0}
                >
                  <span>
                    <TextField
                      onFocus={fetchBrokers}
                      label='Broker'
                      name='broker'
                      select
                      fullWidth
                      value={formik.values.broker}
                      onChange={(event) => {
                        const selectedBroker = brokers.find(
                          (broker) => broker.name === event.target.value
                        );

                        if (selectedBroker) {
                          formik.setFieldValue("broker", selectedBroker.name);
                          formik.setFieldValue("brokerId", selectedBroker.id);
                        }
                      }}
                      sx={{ marginBottom: "16px" }}
                      disabled={selectedAreas.length === 0}
                    >
                      {!brokers.length && (
                        <MenuItem
                          value={
                            typeof formik.values.broker === "string"
                              ? formik.values.broker
                              : ""
                          }
                        >
                          {typeof formik.values.broker === "string"
                            ? formik.values.broker
                            : ""}
                        </MenuItem>
                      )}
                      {brokers && brokers.length > 0 ? (
                        brokers?.map((broker) => (
                          <MenuItem
                            key={broker.id}
                            value={broker.name}
                          >
                            {broker.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value=''>{"No Brokers"}</MenuItem>
                      )}
                    </TextField>
                  </span>
                </Tooltip>
              ) : (
                <TextField
                  label='Broker'
                  name='broker'
                  select
                  fullWidth
                  value={`${currentUser.firstName} ${currentUser.lastName}`}
                  disabled={true}
                  sx={{ marginBottom: "16px" }}
                >
                  <MenuItem
                    key={currentUser.id}
                    value={`${currentUser.firstName} ${currentUser.lastName}`}
                  >
                    {`${currentUser.firstName} ${currentUser.lastName}`}
                  </MenuItem>
                </TextField>
              )}
              <Divider />
              <TextField
                label='Full Name*'
                name='fullName'
                fullWidth
                value={formik.values.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.fullName && formik.errors.fullName
                )}
                helperText={formik.touched.fullName && formik.errors.fullName}
              />
              <TextField
                label='Email'
                name='email'
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                label='Phone*'
                name='phone'
                fullWidth
                value={formik.values?.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id='outlined-adornment-amount'
                  label='Budget*'
                  name='budget'
                  value={formatNumber(formik.values.budget || "")}
                  onChange={(e) => {
                    const rawValue = parseFormattedNumber(
                      (e.target as HTMLInputElement).value
                    );
                    if (rawValue === "") {
                      formik.setFieldValue("budget", "");
                    } else {
                      const formattedValue = rawValue.replace(/^0+/, "");
                      if (/^\d*\.?\d{0,2}$/.test(formattedValue)) {
                        formik.setFieldValue("budget", formattedValue);
                      }
                    }
                  }}
                  onBlur={(e) => {
                    const rawValue = parseFormattedNumber(
                      (e.target as HTMLInputElement).value
                    );
                    if (rawValue === "") {
                      formik.setFieldValue("budget", "");
                    } else {
                      formik.setFieldValue("budget", rawValue);
                    }
                    formik.handleBlur(e);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>{"AED"}</InputAdornment>
                    ),
                  }}
                  error={formik.touched.budget && Boolean(formik.errors.budget)}
                  helperText={formik.touched.budget && formik.errors.budget}
                />
              </FormControl>

              <Autocomplete
                onFocus={fetchAreas}
                fullWidth
                multiple
                options={filteredAreas}
                getOptionLabel={(option) => option}
                disableCloseOnSelect
                value={formik.values.areas}
                onChange={handleSelectionChange}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <MuiCheckbox
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color='success'
                    />
                    {option}
                  </li>
                )}
                noOptionsText={
                  loading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    "No options"
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Area'
                    placeholder='Search...'
                    sx={{ marginBottom: "16px" }}
                  />
                )}
              />

              <TextField
                label='Bedrooms'
                name='bedrooms'
                select
                fullWidth
                value={formik.values.bedrooms}
                onChange={formik.handleChange}
                error={
                  formik.touched.bedrooms && Boolean(formik.errors.bedrooms)
                }
                helperText={Boolean(
                  formik.touched.bedrooms && formik.errors.bedrooms
                )}
              >
                {bedrooms.map((bedrooms, index) => (
                  <MenuItem
                    key={index}
                    value={bedrooms}
                  >
                    {bedrooms}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label='Availability'
                name='availability'
                select
                fullWidth
                value={formik.values?.availability}
                onChange={formik.handleChange}
                error={
                  formik.touched.availability &&
                  Boolean(formik.errors.availability)
                }
                helperText={
                  formik.touched.availability && formik.errors.availability
                }
              >
                {availability.map((availability, index) => (
                  <MenuItem
                    key={index}
                    value={availability}
                  >
                    {availability}
                  </MenuItem>
                ))}
              </TextField>
              <Tooltip
                title={
                  formik.values.email === ""
                    ? "Please provide an email first"
                    : ""
                }
                componentsProps={{
                  arrow: {
                    sx: {
                      color: "#FFF",
                    },
                  },
                  tooltip: {
                    sx: {
                      color: "#7E57C2",
                      backgroundColor: "white",
                      fontSize: "16px",
                      padding: "8px",
                      boxShadow:
                        "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                    },
                  },
                }}
                placement='top'
                arrow
                disableHoverListener={formik.values.email !== ""}
              >
                <span>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='Birthday'
                      value={
                        formik.values?.birthday
                          ? dayjs(formik.values.birthday)
                          : null
                      }
                      onChange={(newValue) =>
                        formik.setFieldValue("birthday", newValue)
                      }
                      minDate={dayjs("1900-01-01")}
                      maxDate={dayjs().subtract(18, "year")}
                      format='DD/MM/YYYY'
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          InputLabelProps: {
                            shrink: true,
                          },
                        },
                      }}
                      disabled={!formik.values.email}
                    />
                  </LocalizationProvider>
                </span>
              </Tooltip>
              <TextField
                label='WhatsApp link'
                name='whatsAppLink'
                fullWidth
                value={formik.values.whatsAppLink}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.whatsAppLink && formik.errors.whatsAppLink
                )}
                helperText={
                  formik.touched.whatsAppLink && formik.errors.whatsAppLink
                }
              />
              <TextField
                label='Home address'
                name='address'
                fullWidth
                value={formik.values.address}
                onChange={formik.handleChange}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "16px",
                }}
              >
                <Button
                  style={{ color: "#000000DE" }}
                  onClick={handleClose}
                >
                  {"Cancel"}
                </Button>
                <Button
                  variant='contained'
                  type='submit'
                  sx={{
                    transition: "background-color color 0.3s ease",
                    "&:disabled": {
                      backgroundColor: "#00000030",
                      color: "grey",
                    },
                    "&:not(:disabled)": {
                      "&:hover": {
                        backgroundColor: "#673AB7",
                        color: "white",
                      },
                      "&:active": {
                        backgroundColor: "#5E35B1",
                        color: "white",
                      },
                    },
                    "&": {
                      background: "#7E57C2",
                      color: "white",
                    },
                  }}
                  disabled={!formik.isValid}
                >
                  {"SAVE CHANGES"}
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Drawer>
    </div>
  );
};

export default EditClientMenu;
