export const GET_ALL_CLIENTS_REQUEST = 'GET_ALL_CLIENTS_REQUEST';
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE = 'GET_ALL_CLIENTS_FAILURE';

export const GET_TEAM_CLIENTS_REQUEST = 'GET_TEAM_CLIENTS_REQUEST';
export const GET_TEAM_CLIENTS_SUCCESS = 'GET_TEAM_CLIENTS_SUCCESS';
export const GET_TEAM_CLIENTS_FAILURE = 'GET_TEAM_CLIENTS_FAILURE';

export const GET_ALL_LEADS_REQUEST = 'GET_ALL_LEADS_REQUEST';
export const GET_ALL_LEADS_SUCCESS = 'GET_ALL_LEADS_SUCCESS';
export const GET_ALL_LEADS_FAILURE = 'GET_ALL_LEADS_FAILURE';

export const GET_TOP_CLIENTS_REQUEST = 'GET_TOP_CLIENTS_REQUEST';
export const GET_TOP_CLIENTS_SUCCESS = 'GET_TOP_CLIENTS_SUCCESS';
export const GET_TOP_CLIENTS_FAILURE = 'GET_TOP_CLIENTS_FAILURE';

export const GET_CLIENT_BY_ID_REQUEST = 'GET_CLIENT_BY_ID_REQUEST';
export const GET_CLIENT_BY_ID_SUCCESS = 'GET_CLIENT_BY_ID_SUCCESS';
export const GET_CLIENT_BY_ID_FAILURE = 'GET_CLIENT_BY_ID_FAILURE';

export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE';

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';

export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE';

export const GET_CLIENT_DOCUMENT_REQUEST = 'GET_CLIENT_DOCUMENT_REQUEST';
export const GET_CLIENT_DOCUMENT_SUCCESS = 'GET_CLIENT_DOCUMENT_SUCCESS';
export const GET_CLIENT_DOCUMENT_FAILURE = 'GET_CLIENT_DOCUMENT_FAILURE';

export const DELETE_CLIENT_DOCUMENT_REQUEST = 'DELETE_CLIENT_DOCUMENT_REQUEST';
export const DELETE_CLIENT_DOCUMENT_SUCCESS = 'DELETE_CLIENT_DOCUMENT_SUCCESS';
export const DELETE_CLIENT_DOCUMENT_FAILURE = 'DELETE_CLIENT_DOCUMENT_FAILURE';

export const UPLOAD_CLIENT_DOCUMENT_REQUEST = 'UPLOAD_CLIENT_DOCUMENT_REQUEST';
export const UPLOAD_CLIENT_DOCUMENT_SUCCESS = 'UPLOAD_CLIENT_DOCUMENT_SUCCESS';
export const UPLOAD_CLIENT_DOCUMENT_FAILURE = 'UPLOAD_CLIENT_DOCUMENT_FAILURE';

export const GET_CUSTOMER_COMMENTS_REQUEST = 'GET_CUSTOMER_COMMENTS_REQUEST';
export const GET_CUSTOMER_COMMENTS_SUCCESS = 'GET_CUSTOMER_COMMENTS_SUCCESS';
export const GET_CUSTOMER_COMMENTS_FAILURE = 'GET_CUSTOMER_COMMENTS_FAILURE';

export const CREATE_CUSTOMER_COMMENT_REQUEST = 'CREATE_CUSTOMER_COMMENT_REQUEST';
export const CREATE_CUSTOMER_COMMENT_SUCCESS = 'CREATE_CUSTOMER_COMMENT_SUCCESS';
export const CREATE_CUSTOMER_COMMENT_FAILURE = 'CREATE_CUSTOMER_COMMENT_FAILURE';

export const GET_CUSTOMER_ACTIVITY_REQUEST = 'GET_CUSTOMER_ACTIVITY_REQUEST';
export const GET_CUSTOMER_ACTIVITY_SUCCESS = 'GET_CUSTOMER_ACTIVITY_SUCCESS';
export const GET_CUSTOMER_ACTIVITY_FAILURE = 'GET_CUSTOMER_ACTIVITY_FAILURE';

export const ADD_PINNED_PROPERTY_REQUEST = 'ADD_PINNED_PROPERTY_REQUEST';
export const ADD_PINNED_PROPERTY_SUCCESS = 'ADD_PINNED_PROPERTY_SUCCESS';
export const ADD_PINNED_PROPERTY_FAILURE = 'ADD_PINNED_PROPERTY_FAILURE';

export const GET_PINNED_PROPERTY_REQUEST = 'GET_PINNED_PROPERTY_REQUEST';
export const GET_PINNED_PROPERTY_SUCCESS = 'GET_PINNED_PROPERTY_SUCCESS';
export const GET_PINNED_PROPERTY_FAILURE = 'GET_PINNED_PROPERTY_FAILURE';

export const DELETE_PINNED_PROPERTY_REQUEST = 'DELETE_PINNED_PROPERTY_REQUEST';
export const DELETE_PINNED_PROPERTY_SUCCESS = 'DELETE_PINNED_PROPERTY_SUCCESS';
export const DELETE_PINNED_PROPERTY_FAILURE = 'DELETE_PINNED_PROPERTY_FAILURE';

export const TOGGLE_ADD_CLIENT_DRAWER = "TOGGLE_ADD_CLIENT_DRAWER";

export const toggleAddClientDrawerActionCreator = (par: boolean) => ({ type: TOGGLE_ADD_CLIENT_DRAWER, payload: par })
