/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";

import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Snackbar,
  Alert,
} from "@mui/material";

import {
  createCustomerComment,
  getCustomerActivity,
} from "../../../../api/clientsApi";
import { Client, Employee } from "../../../../types";
import { AppDispatch } from "../../../../redux/store";
import { tokenStorage } from "../../../../helpers/storageFunctions";
import avatarPlaceholder from "../../../../helpers/avatarPlaceholder";
import leaveCommentArrow from "../../../../svg/leaveCommentArrow.svg";
import { formatDate, formatDateAndTime } from "../../../../helpers/formatDate";

interface Comment {
  id: number;
  message: string;
  employee: Employee;
  createdAt: Date;
}

interface Activity {
  id: number;
  description: string;
  client: Client;
  employee: Employee;
  comment?: Comment | null;
  createdAt: Date;
}

interface AgendaProps {
  clientData: Client | null;
  NewEventAction: boolean;
}

const Agenda: React.FC<AgendaProps> = ({ clientData, NewEventAction }) => {
  const [comment, setComment] = useState<string>("");
  const [activities, setActivities] = useState<Activity[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [charCount, setCharCount] = useState(0);
  const maxChars = 1000;
  const listRef = useRef<HTMLUListElement | null>(null);

  const dispatch: AppDispatch = useDispatch();
  const token = tokenStorage().getAccessToken();

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [activities]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCommentChange = (e: any) => {
    const input = e.target.value;

    if (input.length <= maxChars) {
      setComment(input);
      setCharCount(input.length);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (comment.trim()) {
        handleAddComment();
      }
    }
  };

  const fetchActivities = async () => {
    if (token && clientData?.id) {
      try {
        const response = await dispatch(
          getCustomerActivity(clientData?.id, token)
        );

        if (response?.status === 200) {
          const sortedActivity = response.data.sort((a: Activity, b: Activity) => a.id - b.id);
          setActivities(sortedActivity);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchActivities();
  }, [dispatch, token, clientData, NewEventAction]);

  const handleAddComment = async () => {
    if (token && clientData?.id) {
      try {
        const response = await dispatch(
          createCustomerComment(comment, clientData?.id, token)
        );

        if (response?.status === 201) {
          setComment("");
          setCharCount(0);
          fetchActivities();
          setSnackbarMessage("Comment added!");
          setSnackbarOpen(true);
        }
      } catch (error) {
        setSnackbarMessage("Something went wrong!");
        setSnackbarOpen(true);
      }
    }
  };

  const parseAndFormatEventString = (eventString: string): string => {
    const dateMatch = eventString.match(
      /from (\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3}Z|[+-]\d{2}:\d{2})?) to (\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3}Z|[+-]\d{2}:\d{2})?)/
    );

    if (!dateMatch) {
      return eventString;
    }

    let formattedStartDate;
    let formattedEndDate;

    if (eventString.includes("birthday")) {
      formattedStartDate = formatDate(dateMatch[1]);
      formattedEndDate = formatDate(dateMatch[2]);
    } else {
      formattedStartDate = formatDateAndTime(dateMatch[1]);
      formattedEndDate = formatDateAndTime(dateMatch[2]);
    }

    return eventString
      .replace(dateMatch[1], formattedStartDate)
      .replace(dateMatch[2], formattedEndDate);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        background: "#FFF",
        borderRadius: "6px",
        padding: "8px 16px",
        maxHeight: "800px",
      }}
    >
      <List
        ref={listRef}
        sx={{
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
        }}
      >
        {activities.map((activity) => (
          <ListItem
            key={activity.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingInline: "0px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Avatar
                src={
                  activity.employee.avatarURL ??
                  avatarPlaceholder(
                    activity.employee.firstName,
                    activity.employee.lastName
                  )
                }
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginLeft: "10px",
                }}
              >
                <ListItemText
                  primary={`${activity.employee.firstName} ${activity.employee.lastName}`}
                />
                <span> • </span>
                <ListItemText
                  secondary={formatDateAndTime(activity.createdAt)}
                />
              </Box>
            </Box>
            <ListItemText
              sx={{ margin: "16px 0px 24px 50px" }}
              primary={parseAndFormatEventString(activity.description)}
            />
            <Divider
              sx={{
                width: "100%",
                height: "2px",
                color: "#BDBDBD",
                marginBlock: "12px",
              }}
            />
          </ListItem>
        ))}
      </List>
      <Box sx={{ display: "flex", alignItems: "start", mt: "25px" }}>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <TextField
            label="Write your comment here"
            fullWidth
            multiline
            value={comment}
            onChange={handleCommentChange}
            onKeyDown={handleKeyDown}
            inputProps={{ maxLength: 1000 }}
            sx={{
              padding: "8px 0px",
              "& .MuiOutlinedInput-root": { padding: "9px 8px" },
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              mt: 1,
            }}
          >
            <p>{"Comments are visible to all users in the system."}</p>
            <p style={{ color: charCount >= maxChars ? "red" : "black" }}>
              {charCount}/{maxChars} {"characters"}
            </p>
          </Box>
        </div>
        <Button
          style={{
            marginTop: "8px",
            marginLeft: "16px",
            padding: "8px 22px",
          }}
          onClick={handleAddComment}
          disabled={!comment.trim()}
          sx={{
            mt: 2,
            transition: "background-color color 0.3s ease",
            "&:disabled": {
              backgroundColor: "#00000030",
              color: "grey",
            },
            "&:not(:disabled)": {
              "&:hover": {
                backgroundColor: "#673AB7",
                color: "white",
              },
              "&:active": {
                backgroundColor: "#5E35B1",
                color: "white",
              },
            },
            "&": {
              background: "#7E57C2",
              color: "white",
            },
          }}
        >
          {"ADD"}
          <img
            style={{ marginLeft: "10px" }}
            src={leaveCommentArrow}
            alt="comment arrow"
          />
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Agenda;
