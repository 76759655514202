/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Pagination,
  Typography,
} from "@mui/material";
import EmptyPage from "../../emptyPage/EmptyPage";
import { useNavigate } from "react-router-dom";
import PropertyTable from "../../propertySection/propertyTable/PropertyTable";
import propertyIcon from "../../../svg/propertyIcon.svg";
import { useEffect, useState } from "react";
import EditPreferencesMenu from "../clientProfile/editPreferencesMenu/EditPreferencesMenu";
import { useFormik } from "formik";
import {
  matchingPropertyInitialState,
  matchingPropertyValidationSchema,
  MatchingProps,
} from "./config";
import {
  getCustomerMatchingPreferences,
  getPinnedProperties,
  updateCustomerMatchingPreferences,
} from "../../../api/clientsApi";
import { tokenStorage } from "../../../helpers/storageFunctions";
import { Areas, Employee, EmployeesRoleEnum, Property } from "../../../types";
import { getAllPropertiesApi } from "../../../api/propertiesApi";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import MatchingPropertiesList from "./matchingPropertiesList/MatchingPropertiesList";

interface MatchingPropertiesProps {
  broker: Employee | null;
  clientId: any;
  currentUser: Employee | null;
}

const MatchingProperties: React.FC<MatchingPropertiesProps> = ({
  broker,
  clientId,
  currentUser,
}) => {
  const navigate = useNavigate();
  const token = tokenStorage().getAccessToken();
  const dispatch: AppDispatch = useDispatch();

  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(4);
  const [Matches, setMatches] = useState<Property[]>([]);
  const [pinnedProperties, setPinnedProperties] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [IsLoading, setIsLoading] = useState<boolean>(false);
  const [preferences, setPreferences] = useState<MatchingProps>(
    matchingPropertyInitialState
  );
  const [selectedProperties, setSelectedProperties] = useState<any[]>([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [firstMatch, setFirstMatch] = useState<boolean>(true);

  const pinnedPropertyList = useSelector(
    (state: RootState) => state.clients.pinnedProperties.length
  );

  const filteredMatches = Matches.filter(
    (property) =>
      !pinnedProperties.some((pinned) => pinned.property.id === property.id)
  );

  const handlePropertySelect = (property: any) => {
    if (isSelectionMode) {
      setSelectedProperties((prev) => {
        if (prev.includes(property)) {
          return prev.filter((selectedProp) => selectedProp !== property);
        } else {
          return [...prev, property];
        }
      });
    }

    if (!isSelectionMode) {
      navigate(`/property/${property.id}`);
    }
  };

  useEffect(() => {
    const fetchPinnedProperties = async () => {
      if (!token) {
        return;
      }

      try {
        const response = await dispatch(getPinnedProperties(clientId, token));

        if (response.status !== 200) {
          return;
        }

        setPinnedProperties(response.data);
        return response;
      } catch (error) {
        console.log(error);
      }
    };

    fetchPinnedProperties();
  }, [token, clientId, dispatch, pinnedPropertyList]);

  useEffect(() => {
    const fetchPreferences = async () => {
      if (!token) {
        return;
      }
      setIsLoading(true);
      try {
        const response = await getCustomerMatchingPreferences(clientId, token);

        if (response.status !== 200) {
          return;
        }

        const formattedData = {
          ...response.data,
          areas: response.data.areas
            ? response.data.areas.map((area: Areas) => area.name)
            : null,
        };

        setPreferences(formattedData);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchPreferences();
  }, [clientId, token]);

  const handleMatching = async (values: MatchingProps) => {
    if (!token) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await updateCustomerMatchingPreferences(
        clientId,
        values,
        token
      );

      if (response.status !== 200) {
        return;
      }

      const formattedData = {
        ...response.data,
        areas: response.data.areas
          ? response.data.areas.map((area: Areas) => area.name)
          : null,
      };

      setPreferences(formattedData);
      fetchProperties(values);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchProperties = async (values: MatchingProps) => {
    const areaRange: [number, number] | undefined =
      values.minSqft !== undefined || values.maxSqft !== undefined
        ? [
            values.minSqft !== undefined ? values.minSqft : values.maxSqft || 0,
            values.maxSqft !== undefined ? values.maxSqft : values.minSqft || 0,
          ]
        : undefined;
    const priceRange: [number, number] | undefined =
      values.minPrice !== undefined || values.maxPrice !== undefined
        ? [
            values.minPrice !== undefined
              ? values.minPrice
              : values.maxPrice || 0,
            values.maxPrice !== undefined
              ? values.maxPrice
              : values.minPrice || 0,
          ]
        : undefined;

    const brokerAreas =
      values.areas && values.areas?.length > 0
        ? values.areas
        : broker?.areas.map((area) => area.name);

    const bossAreas =
      values.areas && values.areas?.length > 0 ? values.areas : undefined;

    const requestBody = {
      actionType: values.dealType ? values.dealType : null,
      status:
        values.availability === "VACANT"
          ? "AVAILABLE"
          : values.availability !== "VACANT"
          ? values.availability
          : null,
      propertyType: values.type ? values.type : null,
      districts:
        currentUser?.role === EmployeesRoleEnum.BOSS ||
        currentUser?.role === EmployeesRoleEnum.ADMIN
          ? bossAreas
          : brokerAreas,
      areaRange,
      priceRange,
      bedrooms: values.bedrooms === "ANY" ? null : values.bedrooms,
      bathrooms: values.bathrooms === "ANY" ? null : values.bathrooms,
      building: values.building ? Number(values.building) : null,
    };

    setIsLoading(true);
    setFirstMatch(false);
    if (token && currentUser) {
      try {
        const result = await dispatch(
          getAllPropertiesApi(
            token,
            page,
            limit,
            "createdAt",
            "ASC",
            undefined,
            requestBody.districts,
            requestBody.propertyType ? [requestBody.propertyType] : undefined,
            requestBody.actionType ?? undefined,
            requestBody.status ?? undefined,
            requestBody.areaRange,
            requestBody.priceRange,
            requestBody.bedrooms ? [requestBody.bedrooms] : undefined,
            requestBody.bathrooms ? [requestBody.bathrooms] : undefined,
            undefined
          )
        );

        setMatches(result?.data.data);
        setTotalPages(result?.data.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  useEffect(() => {
    if (!firstMatch) {
      fetchProperties(preferences);
    }
  }, [page]);

  const formik = useFormik({
    initialValues: preferences,
    validationSchema: matchingPropertyValidationSchema,
    onSubmit: handleMatching,
    enableReinitialize: true,
    validateOnMount: true,
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "16px",
        }}
      >
        <Button
          sx={{ display: "none" }}
          onClick={() => setIsSelectionMode(!isSelectionMode)}
        >
          {isSelectionMode ? "CANCEL" : "SELECT"}
        </Button>
        {filteredMatches && filteredMatches.length !== 0 && (
          <EditPreferencesMenu
            formik={formik}
            matches={filteredMatches.length}
            handleMatching={handleMatching}
            fetchProperties={fetchProperties}
            brokerAreas={broker?.areas}
          />
        )}
      </Box>

      {pinnedProperties.length !== 0 && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{ color: "#000000DE", fontSize: "16px", fontWeight: 400 }}
          >
            {"Pinned"}
          </Typography>
          <MatchingPropertiesList
            pinnedProperties={pinnedProperties}
            clientId={clientId}
            setPinnedProperties={setPinnedProperties}
          />
          <Divider sx={{ marginBlock: "32px" }} />
        </Box>
      )}

      {IsLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : filteredMatches && filteredMatches.length > 0 ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PropertyTable
            property={filteredMatches}
            onPropertySelect={handlePropertySelect}
            selectedProperties={selectedProperties}
            user={currentUser}
          />
          <Pagination
            style={{ marginInline: "auto", marginTop: "16px" }}
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            sx={{
              "& .MuiPaginationItem-root": {
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "#673AB7 !important",
                  color: "#FFFFFF !important",
                },
                "&:active": {
                  backgroundColor: "#5E35B1 !important",
                  color: "#FFFFFF !important",
                },
              },
              "& .Mui-selected": {
                backgroundColor: "#7E57C2 !important",
                borderRadius: "4px",
                color: "#FFFFFF !important",
              },
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "32px",
            alignItems: "center",
            background: "#FFF",
            padding: "92px 384px",
          }}
        >
          <EmptyPage
            srcIcon={propertyIcon}
            altDescription={"property icon"}
            headerTitle={
              firstMatch ? "It's empty here." : "0 matching properties."
            }
            underHeaderTitle={
              firstMatch
                ? "There are no matching properties yet."
                : "Try to change the preferences."
            }
          />
          {filteredMatches && filteredMatches.length === 0 && (
            <EditPreferencesMenu
              formik={formik}
              handleMatching={handleMatching}
              fetchProperties={fetchProperties}
              brokerAreas={broker?.areas}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default MatchingProperties;
