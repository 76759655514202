import { AppDispatch } from "../redux/store";
import {
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
  GET_ALL_EMPLOYEES_FAILURE,
  GET_ALL_EMPLOYEES_REQUEST,
  GET_ALL_EMPLOYEES_SUCCESS,
  GET_EMPLOYEE_BY_ID_FAILURE,
  GET_EMPLOYEE_BY_ID_REQUEST,
  GET_EMPLOYEE_BY_ID_SUCCESS,
  UPDATE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
} from "../redux/actions/employees/employees";
import { EmployeeProps, Employee } from "../types";
import api from "./axiosInterceptor";
import { GET_CURRENT_USER_FAILURE, GET_CURRENT_USER_REQUEST, GET_CURRENT_USER_SUCCESS } from "../redux/actions/currentUser";

const DB_URL = process.env.REACT_APP_BASE_URL;

type idType = {
  employeeId: string | undefined;
};

export const getAllEmployeesApi = (
  token: string,
  page: number,
  limit: number,
  sortBy?: string,
  sortOrder?: string,
  roles?: string[],
  subRoles?: string[],
  areas?: string[],
  name?: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_ALL_EMPLOYEES_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          limit,
          sortBy,
          sortOrder: sortBy === "firstName" ? null : sortOrder,
          roles: !roles ? null : roles,
          subRoles: !subRoles ? null : subRoles,
          areas: !areas ? null : areas,
          name: name === "" ? null : name,
        },
      };

      const response = await api.get(`${DB_URL}employees`, config);

      dispatch({
        type: GET_ALL_EMPLOYEES_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_ALL_EMPLOYEES_FAILURE,
        payload: error.message || "Failed to fetch all employees!",
      });
    }
  };
};

export const getEmployeeById = (id: any, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_EMPLOYEE_BY_ID_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.get(`${DB_URL}employees/${id}`, config);
      dispatch({
        type: GET_EMPLOYEE_BY_ID_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_EMPLOYEE_BY_ID_FAILURE,
        payload: error.message || "Failed to find employee!",
      });
    }
  };
};

export const getCurrentUser = (token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: GET_CURRENT_USER_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.get(`${DB_URL}employees/me`, config);
      dispatch({
        type: GET_CURRENT_USER_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: GET_CURRENT_USER_FAILURE,
        payload: error.message || "Failed to find employee!",
      });
    }
  };
};

export const createEmployee = (employeeData: EmployeeProps, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: CREATE_EMPLOYEE_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.post(
        `${DB_URL}employees/create`,
        employeeData,
        config
      );

      const formattedEmployee: Employee = {
        id: response.data.id,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        role: response.data.role,
        subRole: response.data.subRole,
        birthday: response.data.birthday,
        areas: response.data.areas || [],
        phone: response.data.phone,
        email: response.data.email,
        whatsAppLink: response.data.whatsAppLink,
        createdAt: response.data.createdAt,
        avatarURL: response.data.avatarURL || undefined,
        description: response.data.description,
        comments: response.data.comments,
        verified: response.data.verified,
      };

      dispatch({
        type: CREATE_EMPLOYEE_SUCCESS,
        payload: formattedEmployee,
      });


      return response;
    } catch (error: any) {
      dispatch({
        type: CREATE_EMPLOYEE_FAILURE,
        payload: error.message || "Failed to create employee!",
      });

      return error.response;
    }
  };
};

export const uploadPhoto = async (id: string, file: File, token: string) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await api.post(
      `${DB_URL}employees/upload-avatar/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message || "Error uploading photo to S3!");
  }
};

export const resendVerificationMail = async (
  id: number,
  token: string
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await api.get(
      `${DB_URL}employees/resend-email/${id}`,
      config
    );

    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const updateEmployee = (
  id: string,
  values: EmployeeProps,
  token: string
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: UPDATE_EMPLOYEE_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.put(
        `${DB_URL}employees/${id}`,
        values,
        config
      );

      dispatch({
        type: UPDATE_EMPLOYEE_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: UPDATE_EMPLOYEE_FAILURE,
        payload: error.message || "Failed to update employee!",
      });
      return error.response;
    }
  };
};

export const deleteEmployee = ({ employeeId }: idType, token: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: DELETE_EMPLOYEE_REQUEST,
      });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await api.delete(
        `${DB_URL}employees/${employeeId}`,
        config
      );

      dispatch({
        type: DELETE_EMPLOYEE_SUCCESS,
        payload: response.data,
      });

      return response;
    } catch (error: any) {
      dispatch({
        type: DELETE_EMPLOYEE_FAILURE,
        payload: error.message || "Failed to create employee!",
      });
    }
  };
};
