import dayjs from "dayjs";
import * as Yup from "yup";

export interface EventFormValues {
  title: string;
  description: string;
  date: any;
  time: any;
  clientEmail: string;
}

export const initialValues: EventFormValues = {
  title: "",
  description: "",
  date: null,
  time: null,
  clientEmail: "",
};

const today = dayjs().subtract(1, "day");
const maxDate = today.add(10, "year");

export const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  date: Yup.date()
    .nullable()
    .required("Date is required")
    .test("is-valid-date", "Invalid date format", (value) => {
      if (value === null) return true;

      return dayjs(value).isValid();
    })
    .test("is-not-past", "You cannot create an event in the past", (value) => {
      return value && dayjs(value).isAfter(today, "day");
    })
    .test(
      "is-not-too-far",
      "You cannot create an event more than 10 years in the future",
      (value) => {
        return value && dayjs(value).isBefore(maxDate, "day");
      }
    ),
  time: Yup.string()
    .nullable()
    .required("Time is required")
    .test(
      "is-valid-time",
      "The time for today cannot be in the past",
      (value, context) => {
        const date = context.parent.date;

        if (!value || !date) {
          return true;
        }

        const now = dayjs();

        const selectedTime = dayjs(value);

        const hours = selectedTime.hour();
        const minutes = selectedTime.minute();

        const selectedDateTime = dayjs(date).set("hour", hours).set("minute", minutes).set("second", 0);

        if (selectedDateTime.isSame(now, "day")) {
          return selectedDateTime.isAfter(now.add(1, 'minute'));
        } else {
          return true;
        }
      }
    ),
  clientEmail: Yup.string()
    .email("Invalid email format")
    .required("Client must have an email!"),
});
