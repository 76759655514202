import axios, { AxiosResponse, AxiosError, InternalAxiosRequestConfig } from "axios";
import { tokenStorage } from "../helpers/storageFunctions";
import { refreshToken } from "./authApi";
import { refreshGoogleAccessToken } from "./googleApiService";

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

const GOOGLE_API_BASE_URL = process.env.REACT_APP_GOOGLE_API_BASE_URL!;
const googleRefreshAccessToken = tokenStorage().getGoogleRefreshAccessToken()

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const isGoogleApi = config.url?.startsWith(GOOGLE_API_BASE_URL);
    const token = isGoogleApi
      ? tokenStorage().getGoogleAccessToken()
      : tokenStorage().getAccessToken();

    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as CustomAxiosRequestConfig;

    if (!tokenStorage().getAccessToken() && !googleRefreshAccessToken) {
      return Promise.reject(error);
    }

    if (
      error.response?.status === 401 &&
      originalRequest &&
      !originalRequest._retry &&
      !originalRequest.url?.startsWith(GOOGLE_API_BASE_URL)
    ) {
      originalRequest._retry = true;
      try {
        const { accessToken } = await refreshToken();
        tokenStorage().setAccessToken(accessToken);

        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

        return api(originalRequest);
      } catch (refreshError) {
        window.location.href = "/";
        localStorage.clear()
        return Promise.reject(refreshError);
      }
    }

    if (
      error.response?.status === 401 &&
      originalRequest &&
      !originalRequest._retry &&
      originalRequest.url?.startsWith(GOOGLE_API_BASE_URL)
    ) {
      originalRequest._retry = true;
      try {
        const {access_token} = await refreshGoogleAccessToken();
        tokenStorage().setGoogleAccessToken(access_token);

        originalRequest.headers["Authorization"] = `Bearer ${access_token}`;

        return api(originalRequest);
      } catch (refreshError) {
        window.location.href = "/";
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
