import React from "react";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { useAppSelector } from "../../redux/store";
import { ReactComponent as ChatCircle } from "../../svg/ChatCircle.svg";

interface ChatButtonProps {
  toggle: () => void;
}

const ChatButton: React.FC<ChatButtonProps> = ({ toggle: onClick }) => {
  const unreadMessagesCount = useAppSelector(
    (state) => state.chat.unreadMessagesCount
  );

  return (
    <IconButton
      onClick={onClick}
      sx={{
        backgroundColor: "#FFF",
        borderRadius: "50%",
        width: 48,
        height: 48,
        position: "relative",
        transition: "background-color 0.3s",
        "&:hover": {
          backgroundColor: "#EDE7F6",
        },
      }}
    >
      <Badge
        variant='standard'
        badgeContent={unreadMessagesCount > 0 ? unreadMessagesCount : null}
        color='primary'
        sx={{
          "& .MuiBadge-badge": {
            color: "#ffffff",
            backgroundColor: "#7E57C2",
            top: "2px",
            right: "1px",
            fontSize: "12px",
            minWidth: "unset",
          },
        }}
      >
        <ChatCircle />
      </Badge>
    </IconButton>
  );
};

export default ChatButton;
