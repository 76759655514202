import { Employee } from "../../types";
import { GET_CURRENT_USER_SUCCESS } from "../actions/currentUser";

interface CurrentUserState {
    currentUser: Employee | null;
}

const initialState: CurrentUserState = {
    currentUser: null
};

const currentUserReducer = (
    state = initialState,
    action: any
): CurrentUserState => {
    switch (action.type) {
        case GET_CURRENT_USER_SUCCESS:
            return {
                currentUser: action.payload
            };
        default:
            return state;
    }
};

export default currentUserReducer;
