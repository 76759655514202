/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const propertyTypes = [
  { key: "APARTMENT", value: "Apartment" },
  { key: "VILLA", value: "Villa" },
  { key: "TOWNHOUSE", value: "Townhouse" },
  { key: "PENTHOUSE", value: "Penthouse" },
  { key: "DUPLEX", value: "Duplex" },
  { key: "RETAIL", value: "Retail" },
  { key: "OFFICE", value: "Office" },
  { key: "FULL_FLOOR", value: "Full Floor" },
  { key: "HALF_FLOOR", value: "Half Floor" },
  { key: "WHOLE_BUILDING", value: "Whole Building" },
  { key: "LAND", value: "Land" },
  { key: "LABOUR_CAMP", value: "Labour Camp" },
  { key: "CLINIC", value: "Clinic" },
  { key: "FACTORY", value: "Factory" },
  { key: "HOTEL", value: "Hotel" },
  { key: "WAREHOUSE", value: "Warehouse" },
  { key: "GYMNASIUM", value: "Gymnasium" },
  { key: "COMPOUND", value: "Compound" },
];

export interface PropertyInitialValues {
  dealType: "BUY" | "RENT" | "OFF_PLAN";
  nameOfResidence: string;
  district: string;
  city: string;
  building: string | null;
  apartment: number | null;
  type: string;
  area: number | null;
  price: number | null;
  status: "VACANT" | "RENTED" | "SOLD";
  offerDate?: Date | null;
  bedrooms: "Any" | "STUDIO" | "1" | "2" | "3" | "4" | "5" | "6" | "7+";
  bathrooms: "Any" | "1" | "2" | "3" | "4" | "5" | "6" | "7+";
  description: string;
  broker: string;
  brokerId: number | null;
  owner: string;
  ownerId: number | null;
}

export const initialValues: PropertyInitialValues = {
  dealType: "BUY",
  nameOfResidence: "",
  city: "",
  district: "",
  building: null,
  apartment: null,
  type: "",
  area: null,
  price: null,
  status: "VACANT",
  offerDate: null,
  bedrooms: "Any",
  bathrooms: "Any",
  description: "",
  broker: "",
  brokerId: null,
  owner: "",
  ownerId: null,
};

const RegexConstants = {
  DEAL_TYPE: /^[A-Za-z\s-]+$/,
  NAME_OF_RESIDENCE: /^[A-Za-zА-Яа-яЁёІіЇїЄєҐґ\u0600-\u06FF\s\d-]+$/,
  CITY: /^[A-Za-zА-Яа-яЁёІіЇїЄєҐґ\u0600-\u06FF\s-]+$/,
  DISTRICT: /^[A-Za-z\s-]+$/,
  DECIMAL_NUMBER: /^\d+(\.\d{1,2})?$/,
};

export const validationSchema = Yup.object({
  dealType: Yup.string()
    .oneOf(["BUY", "RENT", "OFF_PLAN"])
    .required("Required"),

  nameOfResidence: Yup.string()
    .matches(RegexConstants.NAME_OF_RESIDENCE, {
      message:
        "Only alphabetic characters allowed! Cannot contain only spaces!",
    })
    .trim()
    .required("Required"),

  city: Yup.string()
    .matches(RegexConstants.CITY, {
      message:
        "Only alphabetic characters allowed! Cannot contain only spaces!",
    })
    .trim()
    .nullable()
    .when(["dealType"], {
      is: (dealType: any) => dealType === "OFF_PLAN",
      then: (schema) =>
        schema
          .required("City is required when deal type is 'Off plan'"),
      otherwise: (schema) => schema.nullable(),
    }),

  district: Yup.string()
    .matches(RegexConstants.DISTRICT, {
      message: "Only alphabetic characters allowed!",
    })
    .required("Required"),

  building: Yup.string()
    .required("Required")
    .test(
      "notOnlySpaces",
      "Cannot consist of only spaces!",
      (value) => value?.trim().length > 0
    ),

  apartment: Yup.number()
    .typeError("Apartment must be a number")
    .nullable()
    .transform((value, originalValue) =>
      originalValue === "" ? null : value
    )
    .positive("Apartment must be positive")
    .max(99999999, "Apartment must not exceed 99,999,999"),

  type: Yup.string().required(),
  area: Yup.number()
    .nullable()
    .typeError("Area must be a number")
    .min(1, "Area must be at least 1")
    .max(99999, "Area cannot exceed 99999")
    .test("is-decimal", "Area must have up to 2 decimal places", (value) => {
      return (
        value === null ||
        (typeof value !== "undefined" &&
          RegexConstants.DECIMAL_NUMBER.test(value.toString()))
      );
    })
    .required("Required"),

  price: Yup.number()
    .nullable()
    .typeError('Price must be a number')
    .positive('Price must be positive')
    .max(9_999_999_999, 'Price must not exceed 9,999,999,999')
    .test('is-decimal', 'Price must have up to 2 decimal places', (value) => {
      return value === null || (typeof value !== 'undefined' && RegexConstants.DECIMAL_NUMBER.test(value.toString()));
    })
    .required('Required'),

  status: Yup.string().oneOf(["VACANT", "RENTED", "SOLD"]).required("Required"),

  offerDate: Yup.date()
    .nullable()
    .when(["status"], {
      is: (status: any) => status === "RENTED",
      then: (schema) =>
        schema
          .required("Offer Date is required when status is 'Rented'")
          .min(new Date(), "Offer Date cannot be in the past"),
      otherwise: (schema) => schema.nullable(),
    })
    .when(["status"], {
      is: (status: any) => status === "SOLD",
      then: (schema) =>
        schema
          .required("Offer Date is required when status is 'Sold'")
          .max(new Date(), "Offer Date cannot be in the future"),
      otherwise: (schema) => schema.nullable(),
    }),

  bedrooms: Yup.string()
    .oneOf(["Any", "STUDIO", "1", "2", "3", "4", "5", "6", "7+"])
    .required("Required"),

  bathrooms: Yup.string()
    .oneOf(["Any", "1", "2", "3", "4", "5", "6", "7+"])
    .required("Required"),

  description: Yup.string().notRequired(),
  broker: Yup.string().notRequired(),
  owner: Yup.string().required("Required"),
});
