import { Employee } from "../../../types";
import {
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
  GET_ALL_EMPLOYEES_FAILURE,
  GET_ALL_EMPLOYEES_REQUEST,
  GET_ALL_EMPLOYEES_SUCCESS,
  GET_EMPLOYEE_BY_ID_FAILURE,
  GET_EMPLOYEE_BY_ID_REQUEST,
  GET_EMPLOYEE_BY_ID_SUCCESS,
  UPDATE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  UPLOAD_PHOTO_FAILURE,
  UPLOAD_PHOTO_REQUEST,
  UPLOAD_PHOTO_SUCCESS,
} from "../../actions/employees/employees";

interface EmployeesState {
  loading: boolean;
  employees: Employee[];
  currentUser: Employee | null;
  totalPages: number;
  totalItems: number;
  error: string | null;
}

const initialState: EmployeesState = {
  loading: false,
  employees: [],
  currentUser: null,
  totalPages: 0,
  totalItems: 0,
  error: null,
};

interface GetAllEmployeesRequestAction {
  type: typeof GET_ALL_EMPLOYEES_REQUEST;
}

interface GetAllEmployeesSuccessAction {
  type: typeof GET_ALL_EMPLOYEES_SUCCESS;
  payload: Employee[];
}

interface GetAllEmployeesFailureAction {
  type: typeof GET_ALL_EMPLOYEES_FAILURE;
  payload: string;
}

interface CreateEmployeeRequestAction {
  type: typeof CREATE_EMPLOYEE_REQUEST;
}

interface CreateEmployeeSuccessAction {
  type: typeof CREATE_EMPLOYEE_SUCCESS;
  payload: Employee;
}

interface CreateEmployeeFailureAction {
  type: typeof CREATE_EMPLOYEE_FAILURE;
  payload: string;
}

interface DeleteEmployeeRequestAction {
  type: typeof DELETE_EMPLOYEE_REQUEST;
}

interface DeleteEmployeeSuccessAction {
  type: typeof DELETE_EMPLOYEE_SUCCESS;
  payload: Employee;
}

interface DeleteEmployeeFailureAction {
  type: typeof DELETE_EMPLOYEE_FAILURE;
  payload: string;
}

interface GetEmployeeByIdRequestAction {
  type: typeof GET_EMPLOYEE_BY_ID_REQUEST;
}

interface GetEmployeeByIdSuccessAction {
  type: typeof GET_EMPLOYEE_BY_ID_SUCCESS;
  payload: Employee;
}

interface GetEmployeeByIdFailureAction {
  type: typeof GET_EMPLOYEE_BY_ID_FAILURE;
  payload: string;
}

interface UpdateEmployeeRequestAction {
  type: typeof UPDATE_EMPLOYEE_REQUEST;
}

interface UpdateEmployeeSuccessAction {
  type: typeof UPDATE_EMPLOYEE_SUCCESS;
  payload: string;
}

interface UpdateEmployeeFailureAction {
  type: typeof UPDATE_EMPLOYEE_FAILURE;
  payload: string;
}

interface UploadPhotoRequestAction {
  type: typeof UPLOAD_PHOTO_REQUEST;
}

interface UploadPhotoSuccessAction {
  type: typeof UPLOAD_PHOTO_SUCCESS;
  payload: string;
}

interface UploadPhotoFailureAction {
  type: typeof UPLOAD_PHOTO_FAILURE;
  payload: string;
}

export type EmployeesActionTypes =
  | GetAllEmployeesRequestAction
  | GetAllEmployeesSuccessAction
  | GetAllEmployeesFailureAction
  | CreateEmployeeRequestAction
  | CreateEmployeeSuccessAction
  | CreateEmployeeFailureAction
  | DeleteEmployeeRequestAction
  | DeleteEmployeeSuccessAction
  | DeleteEmployeeFailureAction
  | GetEmployeeByIdRequestAction
  | GetEmployeeByIdSuccessAction
  | GetEmployeeByIdFailureAction
  | UpdateEmployeeRequestAction
  | UpdateEmployeeSuccessAction
  | UpdateEmployeeFailureAction
  | UploadPhotoRequestAction
  | UploadPhotoSuccessAction
  | UploadPhotoFailureAction;

const employeesReducer = (
  state = initialState,
  action: any
): EmployeesState => {
  switch (action.type) {
    case GET_ALL_EMPLOYEES_REQUEST:
    case GET_EMPLOYEE_BY_ID_REQUEST:
    case CREATE_EMPLOYEE_REQUEST:
    case DELETE_EMPLOYEE_REQUEST:
    case UPDATE_EMPLOYEE_REQUEST:
    case UPLOAD_PHOTO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload.items,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
        error: null,
      };
    case CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: [...state.employees, action.payload],
        error: null,
      };
    case GET_EMPLOYEE_BY_ID_SUCCESS:
    case UPDATE_EMPLOYEE_SUCCESS:
    case UPLOAD_PHOTO_SUCCESS:

      return {
        ...state,
        currentUser: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: state.employees.filter(
          (employee) => employee.id !== action.payload.id
        ),
        error: null,
      };
    case GET_ALL_EMPLOYEES_FAILURE:
    case CREATE_EMPLOYEE_FAILURE:
    case GET_EMPLOYEE_BY_ID_FAILURE:
    case DELETE_EMPLOYEE_FAILURE:
    case UPDATE_EMPLOYEE_FAILURE:
    case UPLOAD_PHOTO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default employeesReducer;
