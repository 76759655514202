import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllEmployees, searchEmployeesByName } from '../thunks/employee';

interface Employee {
  id: number;
  firstName: string;
  lastName: string;
  role: string;
}

interface EmployeeState {
  allEmployees: Employee[];
  searchResults: Employee[];
  allLoading: boolean;
  searchLoading: boolean;
  error: string | null;
  searchError: string | null;
}

const initialState: EmployeeState = {
  allEmployees: [],
  searchResults: [],
  allLoading: false,
  searchLoading: false,
  error: null,
  searchError: null,
};

type EmployeeStateBuilder = ActionReducerMapBuilder<EmployeeState>;

const handleFetchAllEmployees = (builder: EmployeeStateBuilder) => {
  builder
    .addCase(fetchAllEmployees.pending, (state) => {
      state.allLoading = true;
      state.error = null;
    })
    .addCase(fetchAllEmployees.fulfilled, (state, action: PayloadAction<Employee[]>) => {
      state.allLoading = false;
      state.allEmployees = action.payload;
    })
    .addCase(fetchAllEmployees.rejected, (state, action) => {
      state.allLoading = false;
      state.error = action.payload as string;
    });
};

const handleSearchEmployeesByName = (builder: EmployeeStateBuilder) => {
  builder
    .addCase(searchEmployeesByName.pending, (state) => {
      state.searchLoading = true;
      state.searchError = null;
    })
    .addCase(searchEmployeesByName.fulfilled, (state, action: PayloadAction<Employee[]>) => {
      state.searchLoading = false;
      state.searchResults = action.payload;
    })
    .addCase(searchEmployeesByName.rejected, (state, action) => {
      state.searchLoading = false;
      state.searchError = action.payload as string;
    });
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleFetchAllEmployees(builder);
    handleSearchEmployeesByName(builder);
  },
});

export default employeeSlice.reducer;
