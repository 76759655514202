import React, { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem, Snackbar, Alert, Badge } from '@mui/material';

import ChatButton from '../chatButton/chatButton';
import { signOutApi } from '../../api/authApi';
import { AppDispatch } from '../../redux/store';
import logoutIcon from '../../svg/logoutIcon.svg';
import myProfileIcon from '../../svg/myProfileIcon.svg';
import notificationBellIcon from '../../svg/notificationBellIcon.svg';
import { tokenStorage } from '../../helpers/storageFunctions';
import { toggleNotificationsDrawerActionCreator } from '../../redux/actions/notifications';
import { notifications } from '../../redux/selectors/notifications';
import { toggleChatOpen } from '../../redux/slices/chatSlice';

const PageHeader = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  const [logoutError, setLogoutError] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const token = tokenStorage().getAccessToken();

  const toggleChat = () => {
    dispatch(toggleChatOpen());
  };

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickMyProfile = async () => {
    navigate(`/my-profile`);
  };

  const handleLogOut = async () => {
    try {
      if (token) {
        const result = await dispatch(signOutApi(token));

        if (result?.status === 200) {
          localStorage.clear();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          setLogoutSuccess(true);
        } else {
          setLogoutError(true);
        }
      }
    } catch (error) {
      setLogoutError(true);
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setLogoutSuccess(false);
    setLogoutError(false);
  };

  const handleOpenNotificationsDrawer = () => {
    dispatch(toggleNotificationsDrawerActionCreator(true));
  };

  const allNotifications = useSelector(notifications);

  const amountOfNewNotifications = allNotifications.filter((not) => !not.isRead).length;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        padding: '8px 24px',
        background: '#FFF',
        boxShadow: '0px 4px 5px #00000020',
        justifyContent: 'space-between',
      }}
    >
      <Button
        sx={{
          color: '#7E57C2',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '400',
          letterSpacing: '0.15px',
          background: 'opacity',
        }}
        onClick={() => navigate('/top-brokers')}
      >
        top-10 brokers
      </Button>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ChatButton toggle={toggleChat} />

        <Button
          sx={{
            minWidth: '48px',
            minHeight: '48px',
            backgroundColor: '#FFF',
            borderRadius: '100px',
            position: 'relative',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#EDE7F6',
            },
          }}
          onClick={handleOpenNotificationsDrawer}
        >
          <img width={20} height={20} src={notificationBellIcon} alt="notification icon" />
          {amountOfNewNotifications > 0 && (
            <Badge
              variant="standard"
              badgeContent={amountOfNewNotifications}
              sx={{
                '& .MuiBadge-badge': {
                  color: '#ffffff',
                  backgroundColor: '#7E57C2',
                  top: '-10px',
                  fontSize: '12px',
                  minWidth: 'unset',
                },
              }}
            />
          )}
        </Button>

        <Button
          style={{
            minWidth: '48px',
            minHeight: '48px',
            background: '#F5F5F5',
            borderRadius: '100px',
          }}
          onClick={(event) => handleMenuClick(event)}
        >
          <img width={20} height={20} src={myProfileIcon} alt="employyes icon" />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => handleClickMyProfile()}>
            <img width={20} height={20} src={myProfileIcon} alt="edit" style={{ marginRight: '10px' }} />
            {'My profile'}
          </MenuItem>
          <MenuItem onClick={() => handleLogOut()}>
            <img width={19} height={18} src={logoutIcon} alt="delete" style={{ marginRight: '10px' }} />
            {'Log out'}
          </MenuItem>
        </Menu>
      </div>

      {/* Сповіщення про успішний логаут */}
      <Snackbar open={logoutSuccess} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Logout successful!
        </Alert>
      </Snackbar>

      {/* Сповіщення про помилку логауту */}
      <Snackbar open={logoutError} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          Logout failed. Please try again.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PageHeader;
