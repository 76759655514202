export function formatBudget(amountStr: any) {
  const amount = parseFloat(amountStr);
  
  if (isNaN(amount)) return "Invalid number";

  return amount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

export function formatArea(amountStr: any) {
  const amount = parseInt(amountStr);
  
  if (isNaN(amount)) return "Invalid number";

  return amount.toLocaleString("en-US");
}

export const formatNumber = (value: string | number) => {
  if (value === null || value === "") return "";
  return new Intl.NumberFormat("en-US").format(Number(value));
};

export const parseFormattedNumber = (formattedValue: string) => {
  return formattedValue.replace(/,/g, "");
};

export const formatNumberWithDecimals = (value: string | number) => {
  if (value === null || value === "") return "";
  const [integerPart, decimalPart] = Number(value).toFixed(2).split(".");
  const formattedInteger = new Intl.NumberFormat("en-US").format(Number(integerPart));
  return `${formattedInteger}.${decimalPart}`;
};