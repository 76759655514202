import { configureStore } from '@reduxjs/toolkit';
import signinReducer from './reducers/signin';
import updateAdminContactReducer from './reducers/updateAdminContact';
import updateBrokerContactReducer from './reducers/updateBrokerContact';
import forgotPasswordReducer from './reducers/forgotPassword';
import setNewPasswordReducer from './reducers/setNewPassword';
import employeesReducer from './reducers/employees/employees';
import signupReducer from './reducers/signup';
import { filtersReducer } from './reducers/filters/filters';
import { sortReducer } from './reducers/sortEmployees/sortEmployees';
import signoutReducer from './reducers/signout';
import propertyReducer from './reducers/property/property';
import workAreasReducer from './reducers/workAreas';
import clientsReducer from './reducers/clients/clients';
import notificationsReducer from './reducers/notifications';
import topBrokersReducer from './reducers/topBrokers';
import chatReducer from './slices/chatSlice';
import currentUserReducer from './reducers/currentUser';
import chatEmployeesReducer from './slices/employeeSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const store = configureStore({
  reducer: {
    employees: employeesReducer,
    clients: clientsReducer,
    signin: signinReducer,
    signup: signupReducer,
    signout: signoutReducer,
    updateAdminContact: updateAdminContactReducer,
    updateBrokerContact: updateBrokerContactReducer,
    forgotPassword: forgotPasswordReducer,
    newPassword: setNewPasswordReducer,
    nameFilter: filtersReducer,
    sortEmployees: sortReducer,
    properties: propertyReducer,
    areas: workAreasReducer,
    notifications: notificationsReducer,
    topBrokers: topBrokersReducer,
    chat: chatReducer,
    currentUser: currentUserReducer,
    chatEmployees: chatEmployeesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
