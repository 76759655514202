import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import whatsAppIcon from "../../../../svg/WhatsAppIconGreen.svg";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";

import mailBoxIcon from "../../../../svg/mailBoxIcon.svg";
import mobilePhoneIcon from "../../../../svg/mobilePhoneIcon.svg";
import { Employee } from "../../../../types";
import avatarPlaceholder from "../../../../helpers/avatarPlaceholder";
import tooltipCopyLogo from "../../../../svg/tooltip-copy-logo.svg";

interface ListingAgentCardProps {
  clientBroker: Employee | null;
}

const ListingAgentCard: React.FC<ListingAgentCardProps> = ({
  clientBroker,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const handleCopy = (text: any) => {
    navigator.clipboard.writeText(text);
    setMessage(`${text} copied!`);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenListingAgentProfile = (id: any) => {
    navigate(`/employees/${id}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
        width: "350px",
      }}
    >
      <Avatar
        onClick={() => handleOpenListingAgentProfile(clientBroker?.id)}
        alt={`${clientBroker?.firstName} ${clientBroker?.lastName}`}
        src={
          clientBroker?.avatarURL
            ? `${clientBroker?.avatarURL}?t=${Date.now()}`
            : `${avatarPlaceholder(
                clientBroker?.firstName,
                clientBroker?.lastName
              )}`
        }
        sx={{
          width: "124px",
          height: "124px",
          marginRight: "8px",
          cursor: "pointer",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "18px",
        }}
      >
        <Box onClick={() => handleOpenListingAgentProfile(clientBroker?.id)}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              height: "40px",
              gap: "4px",
              cursor: "pointer",
            }}
          >
            <Typography sx={{ color: "#000000DE", fontSize: "14px" }}>
              {"Listing agent"}
            </Typography>
            <Typography
              sx={{ color: "#000000DE", fontWeight: 500, fontSize: "20px" }}
            >
              {clientBroker?.firstName} {clientBroker?.lastName}
            </Typography>
          </div>
        </Box>
        <Box sx={{ display: "flex", marginTop: 1, gap: "8px" }}>
          <Tooltip
            onClick={() => handleCopy(clientBroker?.phone)}
            title={
              <Box
                onClick={() => handleCopy(clientBroker?.phone)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <span style={{ marginRight: "4px" }}>
                  {clientBroker?.phone}
                </span>
              </Box>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  color: "#7E57C2",
                  backgroundColor: "white",
                  fontSize: "12px",
                  padding: "8px",
                  boxShadow:
                    "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                },
              },
            }}
          >
            <Button
              onClick={() => handleCopy(clientBroker?.phone)}
              sx={{
                "&": {
                  border: "1px solid #7E57C2",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                },
                "&:hover": {
                  backgroundColor: "#EDE7F6",
                },
              }}
            >
              <img
                width={"9px"}
                height={"15px"}
                src={mobilePhoneIcon}
                alt='phone icon'
              />
            </Button>
          </Tooltip>

          <Tooltip
            title={
              <Box
                onClick={() => handleCopy(clientBroker?.email || "")}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <span style={{ marginRight: "4px" }}>
                  {clientBroker?.email}
                </span>
                <img
                  src={tooltipCopyLogo}
                  alt='logo'
                  style={{ width: "16px", height: "16px" }}
                />
              </Box>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  color: "#7E57C2",
                  backgroundColor: "white",
                  fontSize: "12px",
                  padding: "8px",
                  boxShadow:
                    "0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001F",
                },
              },
            }}
          >
            <Button
              onClick={() => handleCopy(clientBroker?.email)}
              sx={{
                "&": {
                  border: "1px solid #7E57C2",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                },
                "&:hover": {
                  backgroundColor: "#EDE7F6",
                },
              }}
            >
              <img
                width={"15px"}
                height={"12px"}
                src={mailBoxIcon}
                alt='mail icon'
              />
            </Button>
          </Tooltip>

          <Button
            sx={{
              "&": {
                border: "1px solid #7E57C2",
                cursor: "pointer",
                transition: "background-color 0.3s",
              },
              "&:hover": {
                backgroundColor: "#EDE7F6",
              },
            }}
            href={clientBroker?.whatsAppLink || ""}
            target={clientBroker?.whatsAppLink ? "_blank" : undefined}
            rel={clientBroker?.whatsAppLink ? "noopener noreferrer" : undefined}
          >
            <img
              src={whatsAppIcon}
              width={"20px"}
              height={"20px"}
              alt='WhatsApp icon'
            />
          </Button>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity='success'
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};

export default ListingAgentCard;
