import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/axiosInterceptor';

export const fetchChats = createAsyncThunk('chat/fetchChats', async (_, thunkAPI) => {
  try {
    const response = await api.get(`/chats/user`);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch chats');
  }
});

export const fetchChatMessages = createAsyncThunk('chat/fetchMessages', async (chatId: string, thunkAPI) => {
  try {
    const response = await api.get(`/chats/${chatId}/messages`);
    return {
      chatId,
      messages: response.data,
    };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch chat messages');
  }
});

export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async ({ chatId, senderId, content }: { chatId: string; senderId: number; content: string }, thunkAPI) => {
    try {
      const response = await api.post(`/chats/message`, {
        chatId,
        senderId,
        content,
      });
      return {
        chatId,
        message: response.data,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to send message');
    }
  }
);

export const createChat = createAsyncThunk(
  'chat/createChat',
  async ({ participants }: { participants: number[] }, thunkAPI) => {
    try {
      const response = await api.post(`/chats`, {
        participants,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to create chat');
    }
  }
);

export const markMessagesAsRead = createAsyncThunk('chat/markMessagesAsRead', async (chatId: string, thunkAPI) => {
  try {
    await api.patch(`/chats/${chatId}/mark-as-read`);

    return { chatId };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to mark messages as read');
  }
});
