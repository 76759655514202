import { NotificationType } from "./components/notifications/NotificationItem";

export interface emailDataProps {
  email: string;
}

export interface newPasswordProps {
  password: string;
  confirmPassword: string;
}

export interface registeredUserDataProps {
  email: string;
  password: string;
}

export enum EmployeesRoleEnum {
  BOSS = "BOSS",
  ADMIN = "ADMIN",
  BROKER = "BROKER",
}

export enum EmployeesSubRoleEnum {
  SALES = "SALES",
  RENTAL = "RENTAL",
}

export type RoleFilter = {
  BROKER: boolean;
  ADMIN: boolean;
  BOSS: boolean;
  [key: string]: boolean;
};

export interface Areas {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  role: EmployeesRoleEnum;
  subRole: EmployeesSubRoleEnum | null;
  birthday: Date;
  areas: Areas[];
  phone: string;
  email: string;
  whatsAppLink: string;
  createdAt: Date;
  avatarURL?: string;
  description: string;
  comments: string;
  verified: boolean;
}

export interface EmployeeProps {
  firstName: string;
  lastName: string;
  role: string;
  birthday: string;
  areas: string[];
  phone: string;
  email: string;
  whatsAppLink: string;
  description?: string;
  comments?: string;
}

interface Comment {
  user: string;
  text: string;
  date: Date;
}

interface Document {
  id: string;
  fileName: string;
  size: number;
  url: string;
}

interface Image {
  url: string;
}

export interface Property {
  id: string;
  name: string;
  dealType: string;
  buildingType: string;
  building: number;
  apartment: number;
  status: string;
  price: number;
  bedrooms: string;
  bathrooms: string;
  area: number;
  district: Areas;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  broker: Employee;
  owner: Client;
  street: string;
  offerDate: string;
  images: Image[];
  documents: Document[];
  comments: Comment[];
  verification?: string;
  notification?: NotificationType[];
  city?: string | null;
  brokerCommission?: any;
  SBRE?: string;
  bayut?: string;
  propertyfinder?: string;
  isExporting?: boolean;
}

export interface Client {
  id: string;
  fullName: string;
  email?: string;
  phone: string;
  birthday?: string;
  whatsAppLink?: string;
  address?: string | null;
  budget: number | null;
  bedrooms?: string;
  areas?: string[];
  broker?: Employee | null;
  brokerId?: string;
  status?: string;
  type?: string[];
  availability?: string;
  createdAt: Date;
}

export interface Event {
  id: string;
  recurringEventId: string;
  summary: string;
  description?: string;
  start: { dateTime?: string; date?: string };
  end: { dateTime?: string; date?: string };
  htmlLink: string;
  hangoutLink: string;
  attendees: {
    email: string;
    organizer?: boolean;
    responseStatus?: 'accepted' | 'declined' | 'tentative' | 'needsAction';
  }[];
}
