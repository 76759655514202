import { Box } from "@mui/material";

import Agenda from "../agenda/Agenda";
import { Client } from "../../../../types";
import CreateNewEvent from "../../createNewEvent/CreateNewEvent";
import ClientInformation from "../clientInformation/ClientInformation";
import { useEffect, useState } from "react";
import { getEmployeeById } from "../../../../api/employeesApi";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { tokenStorage } from "../../../../helpers/storageFunctions";

interface ActivityProps {
  clientData: Client | null;
}

const ActivityFeed: React.FC<ActivityProps> = ({ clientData }) => {
  const [NewEventAction, setNewEventAction] = useState<boolean>(false);
  const [brokerEmail, setBrokerEmail] = useState<string>("");

  const dispatch: AppDispatch = useDispatch();
  const token = tokenStorage().getAccessToken();

  useEffect(() => {
    const fetchBroker = async () => {
      const brokerId = clientData?.brokerId;
      if (!brokerId) {
        return;
      }

      if (!token) {
        return;
      }

      if (clientData?.brokerId) {
        try {
          const response = await dispatch(getEmployeeById(brokerId, token));

          if (response?.status === 200) {
            const brokerEmail = response.data.email;
            setBrokerEmail(brokerEmail);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchBroker();
  }, [dispatch, token, clientData]);

  return (
    <Box sx={{ display: "flex", gap: "24px", marginTop: "32px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <ClientInformation clientData={clientData} />
        <CreateNewEvent
          clientEmail={clientData?.email}
          brokerEmail={brokerEmail}
          setNewEventAction={setNewEventAction}
        />
      </Box>
      <Agenda clientData={clientData} NewEventAction={NewEventAction} />
    </Box>
  );
};

export default ActivityFeed;
