import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "@mui/material";
// import axios from "axios";
// import { tokenStorage } from "../../helpers/storageFunctions";
import { googleLogin } from "../../api/googleApiService";

type GoogleLoginButtonProps = {
  setGoogleTokenAuth: (value: string) => void;
};

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  setGoogleTokenAuth,
}) => {
  const handleLoginSuccess = async (codeResponse: any) => {
    const accessToken = await googleLogin(codeResponse);
    setGoogleTokenAuth(accessToken);
  };

  const signIn = useGoogleLogin({
    onSuccess: (codeResponse) => handleLoginSuccess(codeResponse),
    flow: "auth-code",
    onError: () => console.error("Login failed"),
    scope:
      "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
  });

  return (
    <Button
      onClick={() => signIn()}
      sx={{
        width: "100%",
        marginTop: "5px",
        transition: "background-color color 0.3s ease",
        "&:disabled": {
          backgroundColor: "#00000030",
          color: "grey",
        },
        "&:not(:disabled)": {
          "&:hover": {
            backgroundColor: "#673AB7",
            color: "white",
          },
          "&:active": {
            backgroundColor: "#5E35B1",
            color: "white",
          },
        },
        "&": {
          background: "#7E57C2",
          color: "white",
        },
      }}
    >
      {"Log in"}
    </Button>
  );
};

export default GoogleLoginButton;
