import dayjs from "dayjs";

export const formatDateAndTime = (isoDate: Date | string) => {
  const date = new Date(isoDate);

  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();

  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  const amPm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12;
  const formattedHours = String(hours).padStart(2, "0");

  const datePart = `${day}/${month}/${year}`;
  const timePart = `${formattedHours}:${minutes} ${amPm}`;

  return `${datePart} ${timePart}`;
};

export const formatDate = (isoDate: Date | string) => {
  return dayjs(isoDate).format("DD/MM/YYYY");
}

export const getTimeAgo = (isoDate: Date | string) => {
  const now = new Date();
  const date = new Date(isoDate);

  const diffInMs = now.getTime() - date.getTime();

  const minutes = Math.floor(diffInMs / (1000 * 60)) % 60;
  const hours = Math.floor(diffInMs / (1000 * 60 * 60)) % 24;
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  let result = "";
  if (days > 0) result += `${days} d `;
  if (hours > 0) result += `${hours} h `;
  if (minutes > 0 || result === "") result += `${minutes} m`;

  return result.trim();
};

export const formatBirthdayDate = (isoDate: Date | string) => {
  return dayjs(isoDate).format("YYYY-MM-DD");
}

