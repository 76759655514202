import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/axiosInterceptor';

export interface SearchEmployeesParams {
  name: string;
  lastName: string;
}

export const searchEmployeesByName = createAsyncThunk(
  'employee/searchEmployeesByName',
  async (params: SearchEmployeesParams, thunkAPI) => {
    try {
      const { name, lastName } = params;

      const response = await api.get('/chats/search-employees', {
        params: { firstName: name, lastName },
      });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to search employees');
    }
  }
);

interface FetchAllEmployeesParams {
  skip?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
}

export const fetchAllEmployees = createAsyncThunk(
  'employee/fetchAllEmployees',
  async (params: FetchAllEmployeesParams, thunkAPI) => {
    try {
      const { skip = 0, limit = 10, sortBy = 'firstName', sortOrder = 'ASC' } = params;

      const response = await api.get('/chats/employees', {
        params: {
          skip: +skip,
          limit: +limit,
          sortBy,
          sortOrder,
        },
      });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch employees');
    }
  }
);
