import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { fetchChats, fetchChatMessages, sendMessage, createChat, markMessagesAsRead } from '../thunks/chat';
import { getCurrentUserId, tokenStorage } from '../../helpers/storageFunctions';

interface Employee {
  id: number;
  firstName: string;
  lastName: string;
  avatarURL?: string;
  email: string;
}

interface Message {
  id: string;
  content: string;
  createdAt: string;
  sender: Employee;
}

interface Chat {
  id: string;
  participant: Employee;
  lastMessage: Message | null;
  unreadCount: number;
}

interface ChatState {
  isChatOpen: boolean;
  chats: Chat[];
  messages: Record<string, Message[]>;
  selectedChatId: string | null;
  unreadMessagesCount: number;
  chatParticipants: Record<string, Employee>;
  loading: boolean;
  error: string | null;
}

const initialState: ChatState = {
  isChatOpen: false,
  chats: [],
  messages: {},
  selectedChatId: null,
  unreadMessagesCount: 0,
  chatParticipants: {},
  loading: false,
  error: null,
};

const sortChatsByLastMessageDate = (chats: Chat[]) =>
  chats.sort(
    (a, b) => new Date(b.lastMessage?.createdAt || '').getTime() - new Date(a.lastMessage?.createdAt || '').getTime()
  );

type ChatStateBuilder = ActionReducerMapBuilder<ChatState>;

const handleFetchChats = (builder: ChatStateBuilder) => {
  builder
    .addCase(fetchChats.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchChats.fulfilled, (state, action) => {
      state.loading = false;
      state.chats = sortChatsByLastMessageDate(action.payload);

      state.chatParticipants = action.payload.reduce((participants: any, chat: any) => {
        if (Array.isArray(chat.participants)) {
          const participant = chat.participants.find(
            (p: any) => p.id !== getCurrentUserId(tokenStorage().getAccessToken())
          );
          if (participant) {
            participants[chat.id] = participant;
          }
        }
        return participants;
      }, {} as Record<string, Employee>);

      state.unreadMessagesCount = action.payload.reduce((total: number, chat: Chat) => total + chat.unreadCount, 0);
    })
    .addCase(fetchChats.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
};
const handleFetchChatMessages = (builder: ChatStateBuilder) => {
  builder
    .addCase(fetchChatMessages.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchChatMessages.fulfilled, (state, action) => {
      state.loading = false;
      state.messages[action.payload.chatId] = action.payload.messages;
    })
    .addCase(fetchChatMessages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
};

const handleSendMessage = (builder: ChatStateBuilder) => {
  builder
    .addCase(sendMessage.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(sendMessage.fulfilled, (state, action) => {
      state.loading = false;
      const { chatId, message } = action.payload;
      state.messages[chatId] = [...(state.messages[chatId] || []), message];

      const chat = state.chats.find((chat) => chat.id === chatId);
      if (chat) {
        chat.lastMessage = message;
      }

      state.chats = sortChatsByLastMessageDate(state.chats);
    })
    .addCase(sendMessage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
};

const handleCreateChat = (builder: ChatStateBuilder) => {
  builder
    .addCase(createChat.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(createChat.fulfilled, (state, action) => {
      state.loading = false;
      state.chats = [action.payload, ...state.chats];
    })
    .addCase(createChat.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
};

const handleMarkMessagesAsRead = (builder: ChatStateBuilder) => {
  builder.addCase(markMessagesAsRead.fulfilled, (state, action) => {
    const { chatId } = action.payload;

    const chat = state.chats.find((chat) => chat.id === chatId);
    if (chat) {
      chat.unreadCount = 0;
    }

    state.unreadMessagesCount = state.chats.reduce((total, chat) => total + chat.unreadCount, 0);
  });
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    selectChat(state, action) {
      state.selectedChatId = action.payload;
    },
    updateUnreadCount(state) {
      state.unreadMessagesCount = state.chats.reduce((total: number, chat: Chat) => total + chat.unreadCount, 0);
    },
    toggleChatOpen(state) {
      state.isChatOpen = !state.isChatOpen;
    },
    setChatOpen(state, action) {
      state.isChatOpen = action.payload;
    },
    updateUnreadMessagesCount(state, action) {
      state.unreadMessagesCount = action.payload;
    },
    addMessageToChat(state, action) {
      const { chatId, message } = action.payload;
      if (!state.messages[chatId]) {
        state.messages[chatId] = [];
      }
      state.messages[chatId].push(message);

      const chat = state.chats.find((chat) => chat.id === chatId);
      if (chat) {
        chat.lastMessage = message;
      }
    },
    incrementUnreadCount(state, action) {
      const chatId = action.payload;
      const chat = state.chats.find((chat) => chat.id === chatId);
      if (chat) {
        chat.unreadCount += 1;
      }
      state.unreadMessagesCount += 1;
    },
  },
  extraReducers: (builder) => {
    handleFetchChats(builder);
    handleFetchChatMessages(builder);
    handleSendMessage(builder);
    handleCreateChat(builder);
    handleMarkMessagesAsRead(builder);
  },
});

export const {
  toggleChatOpen,
  setChatOpen,
  updateUnreadMessagesCount,
  selectChat,
  incrementUnreadCount,
  addMessageToChat,
} = chatSlice.actions;

export default chatSlice.reducer;
