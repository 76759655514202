/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import uploadIcon from "../../../svg/uploadIcon.svg";
import fileIcon from "../../../svg/fileIcon.svg";
import trashIcon from "../../../svg/trashIcon.svg";
import {
  deleteClientDocument,
  uploadClientDocument,
} from "../../../api/clientsApi";
import { tokenStorage } from "../../../helpers/storageFunctions";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  convertBytes,
  shortenFileName,
} from "../../../helpers/documentFunctions";
import { Documents } from "../clientProfile/ClientProfile";
import { Client, Employee, EmployeesRoleEnum } from "../../../types";
import DeleteClientPopup from "../deleteModal/DeleteClientPopup";

interface FileUploadProps {
  documents: Documents[] | null;
  fetchDocuments: () => void;
  currentUser: Employee | null;
  clientId?: string;
  clientData: Client;
}

const FileUpload: React.FC<FileUploadProps> = ({
  documents,
  fetchDocuments,
  currentUser,
  clientId,
  clientData,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const token = tokenStorage().getAccessToken();
  const dispatch: AppDispatch = useDispatch();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        handleUploadDocuments(acceptedFiles);
      }
    },
  });

  const handleUploadDocuments = async (acceptedFiles: File[]) => {
    if (token && clientId) {
      const response = await dispatch(
        uploadClientDocument(clientId, acceptedFiles, token)
      );

      if (response?.status === 201) {
        setSnackbarMessage("Document has been added!");
        setSnackbarOpen(true);
        fetchDocuments();
      }
    }
  };

  const handleDeleteAction = (id: string) => {
    setSelectedDocumentId(id);
    setOpen(true);
  };

  const handleDeleteDocument = async () => {
    if (token) {
      try {
        const response = await dispatch(
          deleteClientDocument(selectedDocumentId, token)
        );

        if (response?.status === 200) {
          handleClosePopup();
          setSnackbarMessage("Document has been deleted!");
          setSnackbarOpen(true);
          fetchDocuments();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleClosePopup = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          display:
            clientData.brokerId !== currentUser?.id &&
            currentUser?.role === EmployeesRoleEnum.BROKER
              ? "none"
              : "block",
          border: "2px dashed #cccccc",
          borderRadius: "8px",
          padding: "16px",
          textAlign: "center",
          cursor: "pointer",
          backgroundColor: isDragActive ? "#f0f0f0" : "#fff",
        }}
      >
        <input {...getInputProps()} />
        <img src={uploadIcon} alt="upload icon" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "5px",
            marginBlock: "10px",
          }}
        >
          <Typography
            sx={{
              color: `${!isDragActive ? "#2196f3" : "black"}`,
              textDecoration: "underline",
              textUnderlineOffset: "5px",
            }}
          >
            {isDragActive ? "Drop here..." : "Click to upload"}
          </Typography>
          <Typography>{isDragActive ? "" : "or drag and drop"}</Typography>
        </div>
        <Typography variant="body2" gutterBottom>
          {"PDF, PNG or JPG"}
        </Typography>
      </Box>
      {documents && documents.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "750px",
            gap: "16px",
            marginTop: "16px",
          }}
        >
          {documents.map((file) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "#FFF",
                borderRadius: "6px",
                padding: "8px",
              }}
              key={file.id}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ padding: "10px 12px" }}
                  src={fileIcon}
                  alt="file"
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Tooltip title={file.fileName} arrow>
                    <Link
                      to={file.url}
                      style={{ textDecoration: "none", color: "black" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {shortenFileName(file.fileName, 12)}
                    </Link>
                  </Tooltip>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#00000099",
                    }}
                  >
                    {convertBytes(file.size)}
                  </Typography>
                </div>
              </div>
              {currentUser?.role !== EmployeesRoleEnum.BROKER && (
                <Button
                  style={{ padding: "10px 12px", background: "#FFF" }}
                  onClick={() => handleDeleteAction(file.id)}
                >
                  <img src={trashIcon} alt="file" />
                </Button>
              )}
            </div>
          ))}
        </Box>
      )}
      <DeleteClientPopup
        open={open}
        onClose={handleClosePopup}
        onConfirm={handleDeleteDocument}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FileUpload;
